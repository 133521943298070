<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewVehicleForm"
        ref="addNewVehicleForm"
        v-model="isFormValid"
        class="custom-form pt-8"
        @submit.prevent="submitNewVehicleForm"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Nr rejestracyjny"
              v-model="vehicle.licensePlate"
              :rules="[rules.required]"
              placeholder="Wpisz numer rejestracyjny"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Marka"
              v-model="vehicle.brand"
              :rules="[rules.required]"
              placeholder="Wpisz nazwę marki"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Model"
              v-model="vehicle.model"
              :rules="[rules.required]"
              placeholder="Wpisz nazwę modelu"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Kolor"
              v-model="vehicle.color"
              placeholder="Wpisz rodzaj koloru"
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              outlined
              :items="vehicleTypes"
              :rules="[rules.required]"
              label="Typ pojazdu"
              v-model="vehicle.vehicleType"
              placeholder="Wybierz typ pojazdu"
              dense
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              outlined
              :items="trackingTypes"
              :rules="[rules.required]"
              label="Sposób śledzenia"
              v-model="vehicle.trackingType"
              placeholder="Wybierz sposób śledzenia pojazdu"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="DMC [T]"
              v-model.number="vehicle.maxWeight"
              type="number"
              validate-on-blur
              :rules="[rules.required, rules.number, rules.lowerThan(1001)]"
              placeholder="Wpisz dopuszczalną masę całkowitą"
              @wheel="$event.target.blur()"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addNewVehicleForm"
      >
        Dodaj pojazd
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import { parseAsBasicUnit } from '../../utils'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data () {
    return {
      rules,
      isFormValid: true,
      vehicle: {
        licensePlate: null,
        brand: null,
        model: null,
        color: null,
        vehicleType: null,
        maxWeight: null,
        trackingType: null,
      }
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      vehicleTypes: state => state.core.filters.vehicleTypes,
      trackingTypes: state => state.core.filters.vehicleTrackingTypes,
      isProcessing: state => state.vehicles.isProcessing
    })
  },
  mounted () {
    this.vehicle.vehicleType = this.vehicleTypes?.[0]
    this.vehicle.trackingType = this.trackingTypes?.[0]
  },
  methods: {
    ...mapActions({
      addNewVehicle: 'vehicles/addNewItem',
      getVehicles: 'vehicles/getItems',
      closeDialog: 'layout/closeDialog'
    }),
    submitNewVehicleForm () {
      this.$refs.addNewVehicleForm.validate()
      if (this.isFormValid) {
        const maxWeight = parseAsBasicUnit(this.vehicle.maxWeight, 1000)
        const params = {
          ...this.vehicle,
          maxWeight,
          departmentId: this.department.id
        }
        this.addNewVehicle({ params })
          .then(() => {
            this.getVehicles()
            this.closeDialog()
          })
      }
    }
  }
}
</script>
